<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Add New Blacklist
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <v-row>
          <v-col cols="12">
            <CustomTextInput
              v-model="value"
              :required="true"
              header="Pattern"
              class="flex-grow-1"
              placeholder="info@example.com"
              :maxlength="150"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <div class="mt-5">
        <div class="regular-text-gray">
          Enter specific email address or enter a domain starting with @ (ex: @domain.com)
        </div>
      </div>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
export default {
  name: "BlackListEdit",
  components: {CustomTextInput},
  props:{
    blacklistItem:{
      default: () => ({}),
    },
  },
  data(){
    return {
      value: "info@pulsehealth.tech",
    }
  },
  created(){
    this.value = this.blacklistItem.value;
  },
  methods: {
    async on_save(){
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit('save', {
        id: this.blacklistItem.id,
        value: this.value,
      });
    }
  },
}
</script>
