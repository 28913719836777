<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Blacklist"
      regular-button-text="New Blacklist"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    >
      <template #subheader>
        Manage and control your email and/or domain blacklist
      </template>
    </PageHeader>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for blacklists"
      :current-page-number="blacklists_page_number"
      :current-page-size="blacklists_page_size"
      :count-total="blacklists_total"
      :page-sizes="[25, 50, 100]"
      @next-page="get_blacklists_next_page"
      @prev-page="get_blacklists_prev_page"
      @change-page-size="blacklists_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <BlackListTable :blacklists="blacklists" @action="handleAction($event)" />

    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="edited_blacklist = {}">
      <BlackListEdit
        v-if="dialog"
        :blacklist-item="edited_blacklist"
        @save="save_blacklist"
        @dismiss="dialog = false; edited_blacklist = {}"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import BlackListTable from "@/views/Campaigns/components/BlackListTable";
import BlackListEdit from "@/views/Campaigns/components/BlackListEdit";

export default {
  name: "BlackList",
  metaInfo: {
    title: 'Blacklist'
  },
  components: {
    BlackListEdit,
    BlackListTable,
    SearchFieldRowWithPagination,
    PageHeader,
  },
  data() {
    return {
      edited_blacklist: {},
      dialog: false,
      blacklists: [],
      blacklists_total: 0,
      blacklists_page_number: 1,
      blacklists_page_size: 25,
      search_string: null,
    };
  },
  created() {
    this.get_available_blacklists();
  },
  methods: {
    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.blacklists.delete_resource(action.item.id);
        await this.get_available_blacklists();
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_blacklists();
    },
    async save_blacklist(blacklist){

      await this.$rest.blacklists.post_resource(blacklist)
      this.edited_blacklist = {};
      this.dialog = false;
      await this.get_available_blacklists()
    },
    async blacklists_change_page_size(size) {
      this.blacklists_page_size = size;
      this.blacklists_page_number = 1;
      await this.get_available_blacklists();
    },
    async get_blacklists_next_page() {
      this.blacklists_page_number += 1;
      await this.get_available_blacklists();
    },
    async get_blacklists_prev_page() {
      this.blacklists_page_number -= 1;
      await this.get_available_blacklists();
    },
    async get_available_blacklists() {
      const resp = await this.$rest.blacklists.get_collection({
        limit: this.blacklists_page_size,
        page: this.blacklists_page_number,
        value: this.search_string || null,
      });
      this.blacklists = resp.data.items;
      this.blacklists_total = resp.data.totalCount;
    },
  },
};
</script>
