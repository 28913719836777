<template>
  <div class="campaigns-table">
    <v-simple-table>
      <thead>
        <tr class="campaigns-table__header">
          <th>Pattern</th>
          <th />
          <th>Is Domain?</th>
          <th v-if="isPulseAdmin || isPulseUser" />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <template v-if="blacklists.length > 0">
          <tr v-for="(item, i) in blacklists" :key="i">
            <td class="py-1">
              <div class="campaigns-table__campaign-header mb-1">
                {{ item.value }}
              </div>
            </td>
            <td class="py-1">
              <div class="campaigns-table__campaign-subheader">
                <div class="pt-1">
                  <span class="font-weight-bold black--text">Created: </span>
                  {{ new Date(item.createdAt).toDateString() }}
                </div>
                <div class="pt-1">
                  <span class="font-weight-bold black--text">Updated: </span>
                  {{ new Date(item.updatedAt).toDateString() }}
                </div>
              </div>
            </td>

            <td class="py-1">
              <v-checkbox v-model="item.isDomain" disabled />
            </td>
            <td
              v-if="isPulseAdmin || isPulseUser"
              class="v-menu-attach-parent"
            >
              <v-menu nudge-top="-5px" nudge-left="15px" :offset-y="true">
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="elevation-0 outlined-btn px-12"
                    height="34px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click')"
                  >
                    Actions
                  </v-btn>
                </template>
                <OuterList
                  title="Actions"
                  :items="outerListItems"
                  @click="$emit('action', { event: $event, item })"
                />
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <div>
                Currently you do not have a blacklist. To add a domain or email click the button above!
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import { mapGetters } from "vuex";

export default {
  name: "BlackListTable",
  components: { OuterList },
  props: {
    blacklists: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
      outerListItems: [
        {
          label: "Delete Item",
          icon: "mdi-delete-outline",
          value: "delete",
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isPulseAdmin', 'isPulseUser']),
  },
};
</script>

<style scoped>
.panel-button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
  color: #66788e;
  text-transform: none;
  background: #ecf1f5 !important;
}
.panel-button[aria-expanded=true] {
  color: white;
  background: #2b84eb !important;
  opacity: 1 !important;
}
</style>
